import React from 'react';
import './ButtonLink.scss';

const ButtonLink = ({ text, path }) => {
    return (
        <a className="link_button_a" href={path} target="_blank" rel="noreferrer">
            {text}
        </a>
    );
};

export default ButtonLink;
