import React from 'react';
import './DevisionMap.scss';
import mapStyle from './MapStyles';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const DevisionMap = ({ lat, lng }) => {
    const libraries = ['places'];

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDtQS8eNfACMU7ruTAK71LI7cby3gVrnPA',
        libraries,
    });

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }
    const center = {
        lat: +lat, // default latitude
        lng: +lng, // default longitude
    };

    const mapContainerStyle = {
        width: '60vw',
        height: '50vh',
        margin: '0 auto',
    };

    const openGoogleMaps = () => {
        const navigationUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
        window.open(navigationUrl, '_blank');
    };

    return (
        <div>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={17}
                center={center}
                options={{ styles: mapStyle }}
            >
                <Marker position={center} onClick={openGoogleMaps} />
            </GoogleMap>
        </div>
    );
};

export default DevisionMap;
